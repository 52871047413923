<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="popupTitle" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="428px" :before-close="closeMypup">
			<div v-if="pupIsOpen" :key='type'>
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
				</TForm>
				<div class="btmBtn" >
	    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
	    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'>
	    			<span v-if="type=='pass'">确认通过</span>
	    			<span v-else>确定</span>
	    		</el-button>
        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TSignName from '@/components/YTable/signName.vue';
	import pinyin from "js-pinyin";
	import {getPosition} from "@/api/business/order/tenant/order";
	import {getCompanyList,getProjectList} from "@/api/business/order/tenant/order";
	export default {
		props: {
			datas:{
				type:Object,
				default:()=>{
					return {
						orderId:'',
					}
				}
			},
			chekIndex:{
				type: Number,
				default: 1,
			},
			type:{
				type: String,
				default: 'pass',
			},
		},
		components: {TForm,TSignName},
		computed: {
		    pupIsOpen() {
		      	return this.$attrs.isOpen;
		    },
	  	},
		watch:{
			type:{
				deep: true,
				handler(n,o){
					this.resetTFrom();
				},
			},
			pupIsOpen:{
				deep: true,
				handler(n,o){
					if(n){
						console.log('弹窗展开了................')
						
					}else{
						this.resetTFrom();
					}
				},
			},
		},
		data() {
			return {
				editPositionList: [],//空间位置数据
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[],
	  			popupTitle:'转派申请',
	  			materielArrs:[],//物料
	  			oConfig:{},//工单配置信息
	  			applyTypes:{
	  				//不同类型申请的内容
	  				pass:{ 
  					name:'提示',
  					httpUrl:'/organization/enterprise/rent/examine/auditRentEndingOrder',
  					method:'POST',
  					formIts:[
	  						{name: '通过审核',keys: 'tips',value: '是否通过审核申请',type: 'text',labelHide:true},
	  					]
	  				},
	  				reject:{
	  					name:'驳回',
	  					httpUrl:'/organization/enterprise/rent/examine/auditRentEndingOrder',
	  					method:'POST',
	  					formIts:[
	  						{name: '驳回原因',keys: 'examineFailReason',value: '',type: 'input',flex:24,isMust:true},
	  					]
	  				},
	  			},
	  			
	  			
			}
		},
		
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeDevice(val,it){
				//选择设备  把位置赋值到所在位置中
				for(let item of this.$store.state.app.allDeviceArrs){
					if(val==item.deviceId){
						this.htmlDatas.positionId=item.positionId;
						break;
					}
				}
			},
			async initPositionTree(projectId){
				if(this.type=='finishOrder'){
					let res=await this.ApiHttp('/base/tenant/position/selectPositionTree?projectId='+projectId);
					if(res){
						this.common.insertOptions(this.formlist,'positionId',res);//添加选项数据到所属项目
					}
				}
			},
			changeProject(val){
				//切换项目
				this.htmlDatas.positionId=null;
				this.getPosition(val);
			},
			// 联动企业名称
		    changeEnterpriseName(val) {
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.enterpriseCode=n;
		    },
			async resetTFrom(){
				
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
				this.htmlDatas={};
//				let pData=JSON.parse(JSON.stringify(this.applyTypes[this.type]));
				let pData=this.applyTypes[this.type];
				
				console.log('pData-resetTFrom',pData,this.type)
				this.formlist=pData.formIts;
				this.popupTitle=pData.name;
  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		positionIdChange(val){
	  			console.log('val',val)
	  			this.$nextTick(() => {
	  				console.log('isOk',this.$refs.htmlFrom)
	  				this.$refs.htmlFrom.validate();
				})
	  			
	  		},
	  		async initMaterielDatas(){
	  			//获取物料数据
	  			if(this.type=='drawMatter'){
					if(!this.datas.projectId) return;
		  			let res = await this.ApiHttp('/base/tenant/materiel/app/manage/intelligentsearchcode?projectId='+this.datas.projectId);
		  			if(res){
		  				this.materielArrs=res;
		  			}
				}
	  		},
	  		delItMatter(dex){
	  			this.htmlDatas.sonList.splice(dex,1)
	  		},
	  		addItMatter(){
	  			this.htmlDatas.sonList.push({materielId:'', materielName: "", materielUnit: "", number: 1});
	  		},
			closeMypup(){
				this.$emit('close')
			},
			initProjectArrs(){
				//加载项目数据
				getProjectList({
						companyId: null
					}).then((res) => {
					res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);//添加选项数据到所属项目
				});
			},
			getPosition(proId) {
				getPosition(proId).then((res) => {
					let Arr1=[];
					res.map(it=>{
						if(it.positionId){
							Arr1.push(it)
						}
					})
					let data = listToTree(Arr1, "positionId", "parentId");
					this.recursivePosition(data);
				});
			},
			recursivePosition(row) {
				let data = row.map((v) => {
					v.label = v.positionName;
					v.id = v.positionId;
					if(v.children != undefined) {
						this.recursivePosition(v.children);
					}
					return v;
				});
				this.editPositionList = data;
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getOrderConfig(oid){
		    	//获取工单配置
		    	let res = await this.ApiHttp('/order/tenant/orderConfig/getConfigDetails',{
		    		isloading:false,
		    		orderConfigId:oid
		    	});
//		    	res.isAutograph='Y';
		    	this.oConfig={...res};
		    	for(let it of this.formlist){
		    		if(it.keys=='webSign'){
		    			it.isHide=(res.isAutograph=="N");
		    			break;
		    		}
		    	}
		    },
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let keyArr=Object.keys(this.applyTypes);
				let index=keyArr.indexOf(this.type)+1;
				let params={
					...this.htmlDatas,
					examineSequence:this.chekIndex,
					rentOrderId:this.datas.rentOrderId,
					rentEndingId:this.datas.rentEndingId,
				}
				console.log('params',params)
				let urls=this.applyTypes[this.type].httpUrl;
				let res = await this.ApiHttp(urls,params,'post');
				if(res){
					this.$message({type:'success',message:'操作成功'});
					this.closeMypup();
					this.$emit('resetDetail');//刷新详情
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.inpNum{
	padding-left:8px;
}
.mtWarp{
	padding-top: 18px;
}
.itMtrow{
	padding:8px 0;
}
.tx_tlue{
	color:#1A4CEC;
}
.signWarp{
	height: 168px;
	padding-top: 24px;
}
</style>