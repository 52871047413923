var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.formlist.length > 0
      ? _c("div", { staticClass: "infoBox" }, [
          _c(
            "div",
            { staticClass: "if_name" },
            [
              _vm._v(_vm._s(_vm.formTitle) + " "),
              _vm.isEdit
                ? _c(
                    "el-button",
                    {
                      staticClass: "editBtn txBlue",
                      attrs: { type: "text" },
                      on: { click: _vm.editOrder },
                    },
                    [
                      _c("i", { staticClass: "el-icon-edit-outline" }),
                      _vm._v("修改工单"),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "if_form" },
            [
              _c("TForm", {
                ref: _vm.refFrom,
                attrs: {
                  autoCheck: true,
                  model: _vm.htmlDatas,
                  formlist: _vm.formlist,
                  rowGutter: 16,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "orderCode",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "trCode" }, [
                            _c("div", { staticClass: "_name" }, [
                              _vm._v("工单编号 "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "_val" },
                              [
                                _c("span", { attrs: { id: "copycode" } }, [
                                  _vm._v(
                                    _vm._s(_vm.htmlDatas[scope.current.keys])
                                  ),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "txBlue",
                                    attrs: { type: "text" },
                                    on: { click: _vm.copyCode },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-copy-document",
                                    }),
                                    _vm._v("复制"),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "jumpUrl",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "trCode" }, [
                            _c("div", { staticClass: "_name" }, [
                              _vm._v(_vm._s(scope.current.name) + " "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "_val" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "txBlue",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toNewPage(
                                          _vm.htmlDatas[scope.current.keys]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.htmlDatas[scope.current.keys]
                                        )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  881137274
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }